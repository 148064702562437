<template>
  <div class="agent-sale-table">
    <filter-panel
      v-model="isFilterActive"
      bordered
      class="filter mt-2 py-1"
      @clear="clearFilter"
      @submit="filterData"
    >
      <div v-if="isFilterLoading" class="row justify-content-center p-2">
        <spinner></spinner>
      </div>
      <div class="row py-2 sm-px-2">
        <fd-input
          v-model="filter['name']"
          class="col-12 sm-col-6 px-1 mb-2"
          label="Agent Name"
          name="agentName"
          type="text"
        >
        </fd-input>
      </div>
    </filter-panel>
    <vue-good-table
      class="card"
      mode="remote"
      @on-page-change="onPageChange"
      @on-per-page-change="onPerPageChange"
      @on-row-click="toAgentSalesReport"
      :columns="agentTableColumns"
      :rows="agentTableData"
      :totalRows="agentTablePagination.total"
      :isLoading="isTableLoading"
      :pagination-options="{
        enabled: true,
        mode: 'pages',
        perPage: 30,
        perPageDropdown: perPageOptions,
        dropdownAllowAll: false
      }"
      fixed-header
    >
      <div slot="loadingContent">
        <div class="d-flex justify-content-center">
          <spinner></spinner>
        </div>
      </div>
      <div slot="emptystate">
        <no-data message="Data is not available"></no-data>
      </div>
      <div slot="table-actions" class="p-2">
        <button
          :class="{ active: isFilterActive }"
          class="btn toggle"
          @click="filterToggle"
        >
          <i class="fas fa-filter"></i>
        </button>
      </div>
      <!-- Table Buttons -->
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'actions'">
          <button class="btn edit mr-1">
            <i class="fas fa-pen"></i>
          </button>
          <button class="btn delete">
            <i class="fas fa-trash"></i>
          </button>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import SalesReportAPI from "@/api/v1/salesReport";
import { numberWithCommas } from "@/utils/string";

export default {
  components: {
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [vueGoodTable],
  props: {
    branchId: {
      type: [Number, String],
      required: true
    }
  },
  data: function () {
    return {
      isTableLoading: false,
      agentTableData: [],
      agentTablePagination: {
        total: 0
      },
      agentTableColumns: [
        {
          label: "Agent Name",
          field: "agentName",
          sortable: false
        },
        {
          label: "Total Subsale Amount",
          field: "totalSubsaleAmount",
          sortable: false,
          formatFn: (val) => numberWithCommas(Number(val).toFixed(2))
        },
        {
          label: "Total Rent Amount",
          field: "totalRentAmount",
          sortable: false,
          formatFn: (val) => numberWithCommas(Number(val).toFixed(2))
        }
      ],

      tableParams: {
        page: 1,
        perPage: 30
      },

      isFilterActive: false,
      isFilterLoading: false,
      filter: {
        name: ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.getAgentSales();
  },
  methods: {
    toAgentSalesReport(e) {
      let id = e.row.agentId;
      this.$router.push({
        name: "AgentSalesReport",
        params: { branchId: this.branchId, id: id }
      });
    },
    // ========================= Table related methods =========================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.getAgentSales();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.getAgentSales();
    },
    // ========================= Filter related methods ========================
    filterData() {
      this.tableParams.page = 1;
      this.getAgentSales();
    },
    clearFilter() {
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAgentSales();
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    // =========================== API related methods =========================

    async getAgentSales() {
      try {
        this.isTableLoading = true;
        let filteredParam = this.$cleanQueryParam(this.filter);
        let data = await SalesReportAPI.getBranchesSalesAgent(
          this.$route.params.id,
          {
            queries: {
              ...filteredParam,
              page: this.tableParams.page,
              perPage: this.tableParams.perPage
            }
          }
        );

        this.agentTableData = this._.cloneDeep(data.data);
        this.agentTablePagination = this._.cloneDeep(data.meta.pagination);

        this.isTableLoading = false;
      } catch (error) {
        this.isTableLoading = false;
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to get agents data. Please try again later."
        });
        throw error;
      }
    }
  }
};
</script>

<style lang="scss"></style>
